<template>
  <navbar id="navbar" type="default">
    <router-link slot="brand" :to="`/`" class="navbar-brand"><img :src="logoPath" class="logo-blue" alt="ITMO">
    </router-link>
    <li>
      <router-link :to="`/`" exact class="hover">{{ $t('mainMenu.projects') }}</router-link>
    </li>
    <li v-if="isRegisteredUser">
      <router-link to="/personal" class="hover">{{ $t('mainMenu.myProjects') }}</router-link>
    </li>
    <li>
      <router-link :to="`/s/information`" exact class="hover">{{ $t('layout.information') }}</router-link>
    </li>
    <li>
      <router-link :to="`/s/reviews`" exact  class="hover">{{ $t('layout.reviews') }}</router-link>
    </li>
    <li v-if="canSelectLang" class="dropdown">
      <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
        <div><span> {{ langs.find(l => l.code === lang).localName }} <i class="fa fa-angle-down"></i></span></div>
      </a>
      <ul class="dropdown-menu">
        <li v-for="l in langs" :key="l.code" @click.prevent="setLang(l.code)" @touchstart.prevent="setLang(l.code)">
          <a href="#">{{ l.localName }}</a>
        </li>
      </ul>
    </li>
    <template slot="right">
      <template v-if="isRegisteredUser">
        <li v-if="notificationsCount > 0">
          <router-link :to="`/profile/notifications`" exact class="hover">
            <i class="fa fa-bell-o fa-lg"></i>
            <span class="badge badge-primary">{{ notificationsCount }}</span>
          </router-link>
        </li>
        <li class="dropdown">
          <a href="#" class="dropdown-toggle usermenu" data-toggle="dropdown" role="button" aria-haspopup="true"
             aria-expanded="false">
            <div v-if="user" class="user-block">
              <span class="username">{{ user.lastName }} {{ user.firstName }} {{ user.middleName }} <i
                class="fa fa-angle-down"></i></span>
            </div>
          </a>
          <ul class="dropdown-menu">
            <li><a href="/admin" v-if="isAdmin"><i class="fa fa-star"></i>&nbsp; {{ $t('mainMenu.admin') }}</a></li>
            <li>
              <router-link to="/profile"><i class="fa fa-cog"></i>&nbsp; {{ $t('mainMenu.profile') }}</router-link>
            </li>
            <li><a href="/logout"><i class="fa fa-sign-out"></i>&nbsp; {{ $t('mainMenu.logout') }}</a></li>
          </ul>
        </li>
      </template>
      <template v-else>
        <li class="hidden-xs">
          <a :href="`/login`" class="hover">
            <i class="fa fa-sign-in"></i>&nbsp; {{ $t('mainMenu.signIn') }}
          </a>
        </li>
        <li v-if="canRegister" class="hidden-xs">
          <router-link :to="`/register`" class="btn-register hover">
            <i class="fa fa-user-plus"></i>&nbsp; {{ $t('mainMenu.signUp') }}
          </router-link>
        </li>

        <li class="visible-xs" @touchstart.prevent="goto('/login')">
          <a :href="`/login`" class="hover">
            <i class="fa fa-sign-in"></i>&nbsp; {{ $t('mainMenu.signIn') }}
          </a>
        </li>
        <li v-if="canRegister" class="visible-xs"
            @touchstart.prevent="goto('/register')">
          <router-link :to="`/register`" class="btn-register hover">
            <i class="fa fa-user-plus"></i>&nbsp; {{ $t('mainMenu.signUp') }}
          </router-link>
        </li>
      </template>
    </template>
  </navbar>
</template>
<script>
import commonModel from '../../models/common';
import userModel from '../../models/user';
import {navbar, dropdown} from 'vue-strap';

export default {
  name: 'MainMenu',
  components: {
    navbar, dropdown
  },
  data() {
    return {
      lang: null,
      langs: [],
      user: userModel.getCurrentUser(),
    }
  },
  computed: {
    isRegisteredUser() {
      return userModel.isRegisteredUser();
    },
    isAdmin() {
      return userModel.isAdmin();
    },
    currentLangName() {
      return this.lang || '?';
    },
    canRegister() {
      return window._STATE.isRegisterEnabled;
    },
    canSelectLang() {
      return window._STATE.areTranslationsEnabled;
    },
    notificationsCount() {
      return window._STATE.notificationsCount;
    },
    logoPath() {
      const logoLang = this.lang === 'ru' ? 'ru' : 'en';
      return require(`../../assets/images/itmo-${logoLang}.png`);
    }
  },
  methods: {
    async setLang(code) {
      await commonModel.setLang(code);
      const regexpForLangs = RegExp(`^/(${this.langs.map(l => l.code).join('|')})`);
      let newPath = this.$route.path.replace(regexpForLangs, '');
      if (!newPath) {
        newPath = '/';
      }
      this.$router.replace(newPath).catch(() => {
      });
      window.location.reload();
    },
    goto(location) {
      window.location.href = location;
    },
  },
  created() {
    this.lang = commonModel.getCurrentLang();
    this.langs = commonModel.getLangs();
  },
}

</script>
<style scoped>
.navbar {
  margin: 0px;
  border: none;
  background: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.17);
}

.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav > li > .dropdown-menu > li > a {
  color: #333;
  padding: 16.5px 25px;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > .dropdown-menu > li > a:hover, .navbar-default .navbar-nav > li > .dropdown-menu > li > a:focus {
  background: #d9dee4;
}

.nav-menu {
  width: 100%;
  height: 53px;
  position: relative;
  background: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.17);
}

.menu-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  max-width: 1200px;
  min-width: 800px;
  margin: 0 auto;
}

.menu {
  display: flex;
  height: 100%;
  align-items: center;
}

.menu li {
  height: 100%;
}

.menu li a:hover, .menu li a:focus {
  text-decoration: none;
}

.navbar-default .navbar-nav > li > a {
  border-top: 3px solid transparent;
}

.navbar-default .navbar-nav > li > a.router-link-active {
  border-top-color: #1946ba;
}

.menu li.icon-item a {
  width: 60px;
  min-width: 0;
  padding: 0 0 3px 0;
}

.menu-dropdown, .menu li a {
  position: relative;
  display: flex;
  height: 100%;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 19px;
}

.menu li a {
  letter-spacing: -0.2px;
  text-align: left;
  color: #4a4a4a;
  border-top: 3px solid transparent;
  padding-bottom: 2px;
}

.menu-list {
  display: none;
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 100;
  background: #fff;
  border: 1px solid #d9dee4;
}

.menu-dropdown:hover, .menu-dropdown:focus, .menu li a.hover:hover, .menu li a.hover:focus {
  background: #d9dee4;
}

.menu-dropdown:hover .menu-list, .menu-dropdown:focus .menu-list {
  display: block;
}

.menu-list li a {
  display: block;
  color: #5a738e;
  padding: 12px 20px;
}

.menu-list li a:hover, .menu-list li a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}

a.navbar-brand {
  padding: 0;
}

.logo-blue {
  width: 256px;
  object-fit: contain;
  height: 100%;
}

.username, .username .fa {
  vertical-align: top;
}

.btn-register {
  color: #fff !important;
  background-color: #1946ba !important;
}

.btn-register:hover {
  color: #fff !important;
  background-color: #13358d !important;
}

.badge-primary {
  color: #fff;
  background-color: #1946ba;
  position: absolute;
  top: 12px;
  right: 12px;
}
</style>
